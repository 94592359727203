.sidebar-burger-icon {
    display: flex;
    margin: 1rem;
    @media ('min-width: 1024px') {
        display: none;
    }
}

.logo {
    max-height: 5rem;
    width: 100%;
    margin: 0 1.5rem 0 0;
    @media screen and (max-width: 750px) {
        margin-bottom: 1.5rem;
    }
}

.page-body {
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.header {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: .75rem 1.25rem;
    box-shadow: 0 0 2px rgba(0, 0, 0, .5);
    width: 100%;
    position: relative;
    z-index: 10;
    flex-wrap: wrap;
    opacity: 1;

    div {
        align-self: center;
    }

    a {
        align-self: center;
    }
}

.logo {
    max-height: 5rem;
    width: 100%;
    margin: 0 1.5rem 0 0;
    @media screen and (max-width: 750px) {
        margin-bottom: 1.5rem;
    }
}

.links-profile-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    flex: 1;
    justify-content: flex-end;
}

.header-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.profile-logout {
    display: flex;
    padding-bottom: 10px;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
}
