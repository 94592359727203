.page-title-legend-container {
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    padding-left: 0;
    margin: 0 0.5rem 0.5rem 0.5rem;
}

.title {
    justify-content: flex-end;
    padding-right: 0;
}

.legend-container {
    justify-content: flex-end;
    padding-right: 0;
    padding-left: 0;

    div {
        padding-left: 2vw;
        padding-right: 0.5rem;
    }
}

.legend {
    height: 100%;
    justify-content: flex-end;
    padding-right: 0;
    padding-left: 0;

    div {
        padding-left: 2rem;
    }
}
