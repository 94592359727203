@import 'react-big-calendar/lib/sass/variables';

.page-body {
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.scroll-container {
    min-height: 60vh;
    height: 110vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
}

.calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 1rem;
    max-width: 100%;
}

.rbc-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: $event-padding;
    border-radius: $event-border-radius;
    color: $event-color;
    cursor: pointer;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;

    .rbc-slot-selecting & {
        cursor: inherit;
        pointer-events: none;
    }

    &.rbc-selected {
        background-color: darken($event-bg, 10%);
    }

    &:focus {
        outline: 5px auto $event-outline;
    }
}

.rbc-month-row {
    display: inline-table !important;
    flex: 0 0 0 !important;
    min-height: 50px !important;
}
