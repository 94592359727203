.responsive-table-wrapper {
    overflow-x: auto;
    white-space: pre-wrap;
    word-break: initial;
    max-width: 100%;
    max-height: 100%;
    @media print {
        width: 100vw;
        overflow: hidden;
    }
}

.table-auto {
    table-layout: auto;
    width: inherit !important;
    min-width: 100%;
    position: sticky; top: 0;
    overflow-y: auto;

    thead {
        position: sticky;
        tr {
            th {
                background-color: #005A9C;
                color: white;
                @media print {
                    color: black;
                    font-weight: bold;
                    background-color: #f1f1f1 !important;
                    color-adjust: exact !important;
                    -webkit-print-color-adjust: exact !important;
                    print-color-adjust: exact !important;
                }
            }
        }
    }

    tbody {
        background-color: #ffffff;
    }
}

.table-actions-wrapper {
    display: flex;
    font-size: 1.2rem;
    justify-content: flex-end;
    flex-shrink: 1;
    flex-wrap: nowrap;
    overflow: auto;
}

.button-groups-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.filters-button-group {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    overflow-x: auto;
}

.button-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    overflow-x: auto;

    Button {
        margin-right: 5px;
        white-space: nowrap;
    }
}

.search-bar {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 1rem;
}

.print-header {
    @media print {
        color: black; font-weight: bold;
    }
}

.print-body {
    @media print {
        width: 100%;
        scrollbar-width: none;
        overflow: hidden;
    }
}
