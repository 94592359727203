@import "../../theme";

.config-container {
    padding-right: 1rem;
    padding-left: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.page-body {
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.page-title-legend-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.legend-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    div {
        padding-left: 2rem;
        padding-right: 0.5rem;
    }
}
