body {
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body { padding-right: 0 !important }

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        "Courier New",
        monospace;
}
// Fixes the massive scrollbar flickering
.row{
    margin-right:0;
    margin-left:0;
}

// Lastposs is fucky and keeping creating an invisible iframe
iframe { display:none !important;}
