h5, h6 {
    padding-top: 1rem;
}

.on-top {
    z-index: 1050;
}

.form-buttons {
    padding-top: 10px;
    margin-left: auto;
    margin-right: 10px;
    border-radius: 10px;
    min-width: 80px;
}

.close-button {
    margin-right: 5px;
}
