@import '/src/_theme';

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    background-color: $logo-red;
    opacity: 70%;
    border-style: solid;
    border-width: 2px;
    border-color: black;
}

.number {
    justify-content: center;
    color: black;
    display: flex;
    top: -2px;
    transform: translate(0, -2px);
}
