@import '/src/theme';

.page-body {
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
}

.config-container {
    padding-right: 1rem;
    padding-left: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.toggle-button {
    display: flex;
    flex-shrink: 1;
    flex-wrap: nowrap;
    border-radius: 10px;
    font-size: 1rem;
    background-color: $color-ocean-blue;
    margin-right: 1rem;
}

.export-button {
    @extend .toggle-button;
    margin-right: 0;
    margin-left: 1rem;
}

.side-panel {
    height: 100%;
    border-radius: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
