.on-top {
    z-index: 1050;
}

.form-buttons {
    padding-top: 10px;
    margin-left: auto;
    margin-right: 10px;
    border-radius: 10px;
    min-width: 80px;
}

.close-button {
    margin-right: 5px;
}

.datepicker {
    width: 50% !important;
}
