@import "../../../theme";

.login-page {
    padding: 2%;
    margin: auto;
    height: 100vh;
    background-color: #f9f9f9;
}

[data-theme='dark'] .login-page {
    background-color: $color-dark-background;
}

.logo {
    display: flex;
    justify-content: center;

    img {
        max-width: 20rem;
    }
}

.login-form {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-button {
    justify-content: center;
    padding-top: 20px;

    button {
        width: 50%;
    }
}

.forgot-password {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

Form.Control {

}

.test > Row > Form.Control.Feedback {
    border: 1px solid purple;
    height: 10rem;
    Row {
        Form.Control.Feedback {
        }
    }
}

