.page-body {
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.config-container {
    padding-right: 0;
    padding-left: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}


.side-panel {
    height: 100%;
    border-radius: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.link {
    color: #3b5872;
    cursor: pointer;
}

.link:hover {
    color: #213140;
    text-decoration: underline;
}

.page-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
