@import "../../theme";

.config-container {
    padding-right: 1rem;
    padding-left: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.page-title {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    align-items: flex-start;
}

.page-body {
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
}

.video-and-list-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.video {
    flex-direction: row;
    min-width: 20rem;
    flex: 1;

    video {
        min-width: 10rem;
        position: relative;
        width: 100%;
    }
}

.videos-list-item {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex: 1;
}

.thumbnail {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 30%;
}

.modal-description {
    display: flex;
    justify-content: flex-start;
    margin: 0 1rem 1rem;
    flex-wrap: wrap;
    word-break: break-word;
}
