@import '../../../theme';

.container {
    min-height: 100vh;
    padding-right: 0;
    padding-left: 0;
}

.page-body {
    display: flex;
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    padding: 0;
    flex-wrap: nowrap;
}

.image-container {
    position: relative;
    overflow: hidden;
    height: 100vh;

    @media screen and (max-width: 991px) {
        position: absolute;
        width: 0;
        height: 0;
    }
}

.background-image {
    object-fit: contain;
    margin-left: 50%;
    transform: translateX(-50%);
    min-height: 100%;
    min-width: 100%;
}

.logo {
    object-fit: contain;
    max-height: 10rem;
    max-width: 70%;
    margin: 1rem 0;
}

.auth-content {
    display: flex;
    width: 60rem;
    max-width: 40%;
    height: 100vh;
    margin-right: auto;
    padding: 0;
    z-index: 90;

    @media screen and (max-width: 991px) {
        width: 100vw;
        max-width: 100%;
    }
}

.footer {
    position: absolute;
    overflow: hidden;
    display: flex;
    bottom: 0;
    right: 0;
    z-index: 100;
}

.fill-col {
    display: flex;
    flex-direction: column;
    flex: 1;
}
